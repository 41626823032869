export default async function scrollBehavior (to, from, savedPosition) {
  if (savedPosition) {
    return savedPosition
  }
  const scrollTo = { x: 0, y: 0 }
  if (to.hash) {
    const name = to.hash.replace(/^#/, '')
    // eslint-disable-next-line promise/param-names
    await new Promise(r => setTimeout(r, 500))
    const element = document.querySelector(
      `[name="${name}"]`
    )
    if (element) {
      if (window.innerWidth <= 768) {
        scrollTo.y = element.getBoundingClientRect().top + window.pageYOffset - 44
      } else {
        scrollTo.y = element.getBoundingClientRect().top + window.pageYOffset
      }
      return scrollTo
    }

    // Special handling needed for finding headings in web components due to shadow DOM. https://bitsofco.de/what-is-the-shadow-dom/#adomwithinadom
    // May need to refactor if more components have headings to be searched.
    const webComponent = document.querySelector('brand-email-signature-generator')
    if (webComponent) {
      const heading = webComponent.shadowRoot.querySelector(`[name="${name}"]`)
      if (heading) {
        scrollTo.y = heading.getBoundingClientRect().top + window.pageYOffset
      }
    }
  }

  return scrollTo
}
