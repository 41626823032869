import dayjs from 'dayjs'

/**
 * Takes a string representation of a date and returns a string formatted
 * per AP guidelines. (March, April, May, June, July spelled in full,
 * three letter abbreviations for all others besides September => Sept)
 * @param String input
 * @returns String
 */
export function getAPformat (input) {
  const date = dayjs(input)
  // Months are 0 based in JS; 2 = March
  if ([2, 3, 4, 5, 6].includes(date.month())) {
    return date
      .format('MMMM D, YYYY')
  } else {
    return date
      .format('MMM. D, YYYY')
      .replace('Sep', 'Sept')
  }
}

export function getHash (title) {
  return '#' + title
    .toLowerCase()
    .trim()
    .replace(/[^-0-9a-zA-Z ]/g, '')
    .replace(/ /g, '-')
}

export function validateEmail (email) {
  return /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(email)
}

/**
 * Adds a style property to a property string if it is not already present
 * @param {string} property The property to add
 * @param {string} currentPropertyString The element's current property string
 * @returns {string} The new property string
 */
export function addStyleProperty (property, currentPropertyString) {
  if (currentPropertyString === '') {
    return property
  } else if (currentPropertyString.includes(property)) {
    return currentPropertyString
  } else {
    return `${currentPropertyString}, ${property}`
  }
};

/**
 * Remove a style property from a property string if it is present
 * @param {string} property The property to remove
 * @param {string} currentPropertyString The element's current property string
 * @returns {string} The new property string
 */
export function removeStyleProperty (property, currentPropertyString) {
  if (currentPropertyString === '' || !currentPropertyString.includes(property)) {
    return currentPropertyString
  } else if (currentPropertyString.includes(',')) {
    return currentPropertyString
      .split(',')
      .map(prop => prop.trim())
      .filter(prop => prop !== property)
      .join(',')
  } else {
    return currentPropertyString
  }
};
