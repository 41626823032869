<template>
  <a
    :class="[$style.link, {[$style.active]: active}]"
    :href="href"
    @click="handleClick"
  >
    <slot />
  </a>
</template>

<script>
export default {
  props: {
    href: {
      type: String,
      default: '#'
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleClick (e) {
      this.$emit('click', e)
    }
  }
}
</script>

<style lang="scss" module>
  .link {
    @media (max-width:767px) {
      padding: 16px 0;
      width: 100%;
    }

    display: block;
    padding: 12px 0;
    position: relative;
    color: $color-black;
    font-family: $font-heading;
    font-size: 1rem;
    font-weight: $weight-bold;
    text-decoration: none;

    &::after {
      content: '';
      bottom: 0;
      display: block;
      width: 0;
      height: 4px;
      background: $color-scarlet;
      position: absolute;
      transition: width .3s;
      margin: 0 auto;
    }

    &.active::after {
      @media (max-width:767px) {
        background: $color-scarlet;
        height: 24px;
        left: -12px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        transition: height .3s;
        width: 4px;
      }

      width: 100%;
    }
  }
</style>
