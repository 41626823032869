export default function ({ $gtm }) {
  console.log('initializing GTM')
  $gtm.push({
    initCookieBanner: 'full'
  })
  $gtm.init()
}

/**
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    initCookieBanner: 'full'
  })
 */
