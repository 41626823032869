var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('client-only',[_c('SiteHeader',{attrs:{"is-auth":_vm.$initHasAuth}})],1),_vm._v(" "),_c('div',{staticClass:"container",class:_vm.containerWidth},[_c('VueAnnouncer'),_vm._v(" "),_c('main',[_c('nuxt')],1)],1),_vm._v(" "),_c('BaseFooter',{staticClass:"footer",attrs:{"site_name_prefix":"","site_name":"The Ohio State University","address_1":"14 E. 15th Ave.","address_2":"","city":"Columbus","state":"OH","zip":"43201","contact_email":"brandcenter@osu.edu","contact_phone":"","contact_tty":"","links":[ ],"social_links":[
      {
        name: 'Facebook',
        url: 'http://www.facebook.com/osu',
        icon: 'icon-facebook'
      },
      {
        name: 'Instagram',
        url: 'https://instagram.com/theohiostateuniversity/',
        icon: 'icon-instagram'
      },
      {
        name: 'Twitter',
        url: 'http://twitter.com/OhioState',
        icon: 'icon-twitter'
      },
      {
        name: 'LinkedIn',
        url: 'https://www.linkedin.com/school/the-ohio-state-university/',
        icon: 'icon-linkedin'
      },
      {
        name: 'YouTube',
        url: 'http://www.youtube.com/user/OhioStateUniversity',
        icon: 'icon-youtube'
      },
      {
        name: 'TikTok',
        url: 'https://vm.tiktok.com/TTPdYEkbnP/',
        icon: 'icon-tiktok'
      }
    ]},scopedSlots:_vm._u([{key:"links",fn:function(){return [_c('p',{staticClass:"bux_footer__link"},[_c('a',{staticClass:"bux-link",attrs:{"href":"http://www.osu.edu/webmaster.php"}},[_vm._v("\n          Request\n        ")]),_vm._v("\n        an alternate format of this page\n      ")]),_vm._v(" "),_c('p',{staticClass:"bux_footer__link"},[_vm._v("\n        View\n        "),_c('a',{staticClass:"bux-link",attrs:{"href":"https://go.osu.edu/privacy"}},[_vm._v("\n          privacy notice\n        ")])]),_vm._v(" "),_c('p',{staticClass:"bux_footer__link"},[_vm._v("\n        View\n        "),_c('a',{staticClass:"bux-link",attrs:{"href":"https://go.osu.edu/nondiscrimination-notice"}},[_vm._v("\n          non-discrimination notice\n        ")])]),_vm._v(" "),_c('p',{staticClass:"bux_footer__link"},[_vm._v("\n        Update\n        "),_c('button',{staticClass:"cookie-setting-link ot-sdk-show-settings",attrs:{"id":"ot-sdk-btn","type":"button"}},[_vm._v("\n          Cookie Settings\n        ")])])]},proxy:true},{key:"default",fn:function(){return [_c('p',[_vm._v("\n        Page maintained by the "),_c('a',{staticClass:"bux-link",attrs:{"href":"https://universitymarketing.osu.edu"}},[_vm._v("Office of Marketing and Communications")])])]},proxy:true}])}),_vm._v(" "),_c('BackToTop')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }