<template>
  <nuxt-link
    :to="to"
    v-slot="slotProps"
    custom
  >
    <NavLink
      :active="matchExact ? slotProps.isExactActive : slotProps.isActive"
      :href="slotProps.href"
      @click="slotProps.navigate"
    >
      <slot />
    </NavLink>
  </nuxt-link>
</template>

<script>
import NavLink from './NavLink.vue'

export default {
  components: {
    NavLink
  },
  props: {
    to: [String, Object],
    matchExact: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="scss" module>

</style>
