import { transform } from 'node-json-transform'
import jsonApi from './jsonApiClient.js'

const transformMap = {
  item: {
    id: 'id',
    brand_content_children: 'brand_content_children',
    image: 'image',
    main_image: 'main_image',
    parent: 'parent',
    resources: 'resources',
    site: 'site',
    title: 'title',
    slug: 'slug',
    body: 'body',
    description: 'description',
    drupal_internal__nid: 'drupal_internal__nid',
    weight: 'weight',
    show_as_child_content: 'show_as_child_content',
    hide_child_pages_from_sidebar: 'hide_child_pages_from_sidebar',
    allow_student_access: 'allow_student_access',
    hide_child_resources: 'hide_child_resources',
    meta_title: 'meta_title'
  },
  defaults: {
    hide_child_pages_from_sidebar: false,
    brand_content_children: [],
    body: { value: '' },
    resources: []
  },
  operate: [
    {
      run: (resources) => {
        if (resources.data) {
          return resources.data.map(r => ({
            link: r.link.uri,
            description: r.description.value,
            allow_student_access: r.allow_student_access,
            created: r.created,
            title: r.title
          }))
        }
      },
      on: 'resources'
    },
    {
      run: val => val === null ? { value: '' } : val,
      on: 'body'
    } /*,
    {
      run: getImageUrl,
      on: 'main_image'
    }
    */
  ]
}
function getImageUrl (image) {
  // /sites/default/files/media/image/2020/03/brand-center_our-brand-hero.jpg
  // /sites/default/files/styles/header_large/public/media/image/2020/03/brand-center_our-brand-hero.jpg?itok=ZFuBWBU_
  if (image === undefined) { return null }
  const original = image.image.uri.url
  const host = new URL(process.env.DRUPAL_AUTH_URL).host
  const header = original.replace('/sites/default/files/', `//${host}/sites/default/files/styles/header_large/public/`)

  return header
}

const include = [
  'parent',
  'main_image',
  'main_image.image',
  'main_image.bundle',
  'main_image.thumbnail',
  'main_image.image.uid',
  'image',
  'image.bundle',
  'image.image',
  'image.thumbnail',
  'image.image.uid',
  'resources.paragraph_type'
]

export default {
  async get (slug) {
    const options = {
      include: include.join(',')
    }
    if (slug) {
      options.slug = slug
    }
    const data = await jsonApi.get('node/brand_content', options)
    const transformed = transform(data.data, transformMap)
    return transformed
  }
}
