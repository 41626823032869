/* eslint-disable no-unreachable */
import Vue from 'vue'
import axios from 'axios'
import brandAPI from '~/lib/getBrandContent.js'
import { getHash } from '~/lib/util.js'
import checkAuth from '~/plugins/check-auth.js'

function sortLinks (a, b) {
  if (a.weight === b.weight) { return a.title - b.title }
  return a.weight - b.weight
}

export const state = (thing, another) => {
  // debugger
  return {
    isStudent: null,
    isAuth: false,
    isLoaded: true,
    requestedPath: '',
    allowedRoutes: [
      '/access-denied',
      '/brand-guidelines',
      '/brand-guidelines/templates/email-signatures',
      '/external-brand-requests'
    ],
    roles: [],
    brandContent: require('./data/brand-content')
  }
}

export const getters = {
  currentContentArray: (state, getters, thing, that, other) => {
    const { brandContent, isStudent } = state
    const { studentIDs, contentIDs } = getters
    const isBrowser = typeof window === 'object'
    const IDs = (isStudent && isBrowser) ? studentIDs : contentIDs
    // console.log('IDs', studentIDs, contentIDs)
    return IDs.map(id => brandContent[id])
  },
  studentIDs: (state, { contentArray }) => [...contentArray].filter(c => c.allow_student_access).map(c => c.id),
  isSSO: ({ roles }) => !roles.includes('anonymous'),
  hasAuthRole: ({ roles }) => {
    const hasAuthRole = roles.includes('employee_sso') || roles.includes('staff_sso') || roles.includes('guest_sso') || roles.includes('student_sso')
    if (process.env.ALLOW_STUDENT_AUTH === 'true') {
      return hasAuthRole || roles.includes('student_sso')
    }
    return hasAuthRole
  },
  isAccessDenied: (state, { isSSO, hasAuthRole }) => isSSO && !hasAuthRole,
  redirectPath: (state, { isAccessDenied }) => isAccessDenied ? '/access-denied' : '/external-brand-requests',
  searchContent: ({ brandContent }, { currentContentArray, pathBySlug }) => (searchString) => {
    const searchTerms = searchString.split(' ')
    const searchReg = new RegExp(`.{0,25}(\\b${searchTerms.join('|\\b')}).{0,25}`, 'gi')
    if (searchString.length === 0) {
      return []
    }
    if (process.server) { return [] }

    const formatted = currentContentArray
      .filter(node => node.body.value)
      .map((node) => {
        const text = node.body.value
        if (text) {
          const parser = getParser()
          const blockDocument = parser.parseFromString(text, 'text/html')
          const content = getContent(blockDocument.getElementsByTagName('body')[0])
          return Object.assign({}, node, { body: { value: content } })
        }
        return null
      })
      .filter(node => searchReg.test(node.body.value))
      .map((node) => {
        let result
        node.searchWeight = 0
        node.searchBlurb = ''
        do {
          result = searchReg.exec(node.body.value)
          if (result) {
            const bolded = result[0].replace(result[1], `<b>${result[1]}</b>`)
            node.searchWeight++
            node.searchBlurb += `...${bolded}...`
          }
        } while (result)
        node.path = pathBySlug[node.slug]
        return node
      })
      .sort((a, b) => b.searchWeight - a.searchWeight)
    return formatted
    const results = formatted.map((item) => {
      const test = searchReg.test(item.body.value)
      if (test) {
        // get text surrounding
        const reg = new RegExp()
        item.body.highlighted = [...item.body.value.matchAll(searchReg)]
        /*
          item.body.highlighted = item.body.value.replace(searchReg, (match, highlight, offset, string) => {
            const reg = new RegExp(highlight, 'g')
            const result = match.replace(reg, '<b>$&</b>') + ' ... '
            return result
          })
          */
        return item
      }
      return null
    })
      .filter(i => i)
    return results
  },
  contentIDs: state => Object.keys(state.brandContent)
    .map(id => id)
    .sort(),
  brandParents: (state, { currentContentArray }) => {
    if (state.isStudent) {
      return currentContentArray.filter(c => !c.parent.data)
    } else {
      return currentContentArray.filter(c => !c.parent.data)
        .filter(item => item.allow_student_access)
    }
  },
  contentArray: (state, { contentIDs }) => {
    return contentIDs.map(id => state.brandContent[id])
  },
  pathByLocalID: (state, { brandParents, getChildren }) => {
    const paths = {}
    function getPaths (content, level = 0, basePath = '') {
      const isEditor = basePath === '/brand-guidelines/templates/email-templates/editors'
      const isHash = basePath !== '/brand-guidelines/templates/email-templates' && (content.show_as_child_content)
      const path = !isEditor && isHash ? `${basePath}${getHash(content.title)}` : `${basePath}/${content.slug}`
      paths[content.drupal_internal__nid] = path
      if (content.brand_content_children) {
        const children = getChildren(content.brand_content_children)
        children.forEach(c => getPaths(c, level + 1, path))
      }
    }
    brandParents.forEach(p => getPaths(p))
    return paths
  },
  pathBySlug: (state, { brandParents, getChildren }) => {
    const paths = {}
    function getPaths (content, level = 0, basePath = '') {
      const isEditor = basePath === '/brand-guidelines/templates/email-templates/editors'
      const isHash = !isEditor && basePath !== '/brand-guidelines/templates/email-templates' && (content.show_as_child_content)
      const path = isHash ? `${basePath}${getHash(content.title)}` : `${basePath}/${content.slug}`
      paths[content.slug] = path
      if (content.brand_content_children) {
        const children = getChildren(content.brand_content_children)
        children.forEach(c => getPaths(c, level + 1, path))
      }
    }
    brandParents.forEach(p => getPaths(p))
    return paths
  },
  contentBySlug: (state, { currentContentArray }) => {
    const content = {}
    currentContentArray.forEach((c) => {
      content[c.slug] = c
    })
    return content
  },
  contentByLocalID: (state, { currentContentArray }) => {
    const content = {}
    currentContentArray.forEach((c) => {
      content[c.drupal_internal__nid] = c
    })
    return content
  },
  getChildren: (state, { studentIDs }) => children =>
    children
      .map(c => c.id)
      .filter((c) => {
        if (!state.isStudent) { return true }
        return studentIDs.includes(c)
      })
      .map(id => state.brandContent[id])
      .sort(sortLinks),
  getChildrenRecursive: (state, getters) => (childParam, level = 1) => {
    const children = childParam.filter(c => state.isStudent ? getters.studentIDs.includes(c.id) : true)
    const result = getters.getChildren(children.map(c => ({ ...c, level })))
    return result.map((c) => {
      // eslint-disable-next-line camelcase
      const brand_content_children = getters.getChildrenRecursive(c.brand_content_children, level + 1)
      return Object.assign({}, c, { brand_content_children })
    })
  },
  linearNav: (state, getters) => (baseUrl) => {
    const slug = baseUrl.split('/').pop()
    const platform = getters.contentBySlug[slug]
    const childItems = getters.getChildrenRecursive(platform.brand_content_children)
    const flatten = (obj, level = 1, basePath) => {
      basePath = basePath ?? baseUrl
      const array = Array.isArray(obj) ? obj : [obj]
      // TODO: Refactor to use this logic in other menu
      return array.reduce((acc, value) => {
        const path = `${basePath}/${value.slug}`
        if (!value.show_as_child_content && level < 3) {
          value.navPath = path
          acc.push(value)
        }
        if (value.brand_content_children) {
          acc = acc.concat(flatten(value.brand_content_children, level + 1, path))
          delete value.brand_content_children
        }
        return acc
      }, [])
    }
    if (state.isStudent) {
      return flatten(childItems)
        .filter(item => item.allow_student_access)
    }
    return flatten(childItems)
  },
  isEditor: (state) => {
    return state.roles.includes('decoupled_editor')
  }
}

export const mutations = {
  login (state) {
    state.isAuth = true
  },
  logout ({ isAuth }) {
    isAuth = false
  },
  setRequestedPath (state, path) {
    const requested = path || window.location.href
    state.requestedPath = requested
  },
  setRoles (state, roles) {
    state.roles = [...roles]
  },
  setStudent (state, isStudent) {
    state.isStudent = isStudent
  },
  loadContent (state, data) {
    data.forEach((c) => {
      Vue.set(state.brandContent, c.id, c)
    })
    state.isLoaded = true
  }
}

export const actions = {
  async checkAuth ({ state, commit, getters }) {
    if (state.isAuth) {
      return true
    }
    // const roles = this.$initRoles
    // const student = this.$initStudent
    const roles = await pollRoles()
    const student = await isStudent(roles)
    const hasAuth = getters.hasAuthRole
    // debug({ function: 'checkAuth.action', hasAuth, student, roles })
    commit('setRoles', roles)
    commit('setStudent', student)
    if (hasAuth) {
      commit('login')
    }
    return getters.hasAuthRole
  },
  async getContent (store) {
    if (!store.state.isLoaded) {
      const data = await brandAPI.get('node/brand_content', {
        include: 'image'
        /*
        filter: {
          'site.id': '2321275b-6360-481a-a304-d0db65f96636'
        }
        */
      })
      store.commit('loadContent', data)
    }
  }
}

export async function pollRoles (url) {
  if (process.env.ALLOW_ACCESS === 'true') {
    return ['guest_sso']
  }
  const domain = process.env.DRUPAL_API_URL || 'https://live-decoupler.pantheonsite.io/api'
  const baseURL = (url || /(https?:\/\/[^/]*)/.exec(domain)[1]) + '/brand-api/current-user'
  const authAxios = axios.create({
    baseURL,
    withCredentials: true
  })
  const result = await authAxios.get()
    .catch(e => console.error('error polling roles', baseURL, e))
  return result ? result.data : []
}

export async function pollAuth (url) {
  const roles = await pollRoles(url)
  const isApprovedRole = roles.includes('employee_sso') || roles.includes('staff_sso') || roles.includes('guest_sso') || roles.includes('student_sso')
  return isApprovedRole
}

/**
 * Checks to see if the user has *only* member and student roles set.
 * Useful for determining access level needed.
 * @returns boolean
 */
export async function isStudent (roles = false) {
  roles = roles || await pollRoles()
  const hasMemberSSO = roles.includes('member_sso')
  const hasGuestSSO = roles.includes('guest_sso')
  const hasStudentSSO = roles.includes('student_sso')
  const hasEmployeeSSO = roles.includes('employee_sso') || roles.includes('staff_sso')

  if (process.client && hasMemberSSO && process.env.FORCE_STUDENT_ROLE === 'true') {
    return true
  }

  return hasMemberSSO && hasStudentSSO && !hasEmployeeSSO && !hasGuestSSO
}

export function getParser () {
  if (process.client) {
    // eslint-disable-next-line no-use-before-define
    return new window.DOMParser()
  }
  const DOMParser = require('xmldom').DOMParser
  return new DOMParser({
    locator: {},
    errorHandler: (level, message) => console.log(level, message)
  })
}

function getContent (el) {
  if (el) {
    const text = el.textContent
    return text.replace(/\n/g, '')
  }
  return ''
}

export async function debug (message) {
  const domain = process.env.DRUPAL_API_URL || 'https://live-decoupler.pantheonsite.io/api'
  const baseURL = (/(https?:\/\/[^/]*)/.exec(domain)[1]) + '/brand-api/debug'
  const debugAxios = axios.create({
    baseURL,
    withCredentials: true
  })
  if (process.client) {
    const result = await debugAxios.post(null, { message })
      .catch(error => console.error('error in debug function', error, baseURL))
    return result.data
  }
  console.log('skipping debug log to CMS')
  return null
}
