<template>
  <client-only>
    <div :class="$style.errorPageWrapper">
      <BaseHeading
        :level="1"
      >
        O-H! N-O!
      </BaseHeading>

      <p>We can’t find the page you’re looking for. Some tips:</p>
      <ul class="bux-list-ul">
        <li>Verify the link you typed.</li>
        <li>Try to visit this page in a few minutes.</li>
        <li>
          Try using site <nuxt-link class="bux-link" to="/search">
            search
          </nuxt-link>.
        </li>
      </ul>
    </div>
  </client-only>
</template>

<script>
import { BaseHeading } from '@umarketing/osu-design-system'
export default {
  props: ['error'],
  components: {
    BaseHeading
  },
  head () {
    const head = {
      title: '404 Message – Brand Center | The Ohio State University',
      meta: [
        { hid: 'description', name: 'description', content: 'O-H! N-O! We are so sorry you can’t find the page you’re looking for, but here are some tips to get what you need about the Ohio State brand.' }
      ]
    }
    return head
  },
  mounted () {
    console.error('Nuxt Error', this.error)
  }
}

</script>

<style lang="scss" module>
  .errorPageWrapper {
    margin: $space-l 0;

    ul {
      margin: 0;
      padding: 0 0 0 $space-m;
    }
  }
</style>
<style lang="scss">
// ========================================
// KEYBOARD FOCUS for accessibility
// see plugin: focus-visible.js
.fc-button:focus,
button:focus,
a:focus {
  outline: none;
}
.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}
.js-focus-visible .focus-visible {
  outline: 2px solid #3492b8 !important;
  outline-offset: 2px !important;
}
// END KEYBOARD FOCUS
// ========================================
</style>
