<template>
  <div
    :class="$style.nav"
  >
    <client-only>
      <nav
        aria-label="main navigation"
        v-show="isAuth"
        :class="{ [$style.homeNav] : isAuth }"
      >
        <ul id="main-nav-links" role="menu" aria-labelledby="main-nav-trigger">
          <li role="none">
            <NuxtNavLink
              to="/"
              class="mainNavLink"
              :tabindex="setTabIndex"
              role="menuitem"
              @keydown.native="trapMenuFocus($event)"
              @click.native="$emit('changemenu', 'close')"
            >
              Home
            </NuxtNavLink>
          </li>
          <li role="none">
            <NuxtNavLink
              class="mainNavLink"
              to="/brand-guidelines"
              :match-exact="false"
              :tabindex="setTabIndex"
              role="menuitem"
              @keydown.native="trapMenuFocus($event)"
              @click.native="$emit('changemenu', 'close')"
            >
              Brand Guidelines
            </NuxtNavLink>
          </li>
          <li role="none">
            <NuxtNavLink
              class="mainNavLink"
              to="/whats-new"
              :match-exact="false"
              :tabindex="setTabIndex"
              role="menuitem"
              @keydown.native="trapMenuFocus($event)"
              @click.native="$emit('changemenu', 'close')"
            >
              What's New
            </NuxtNavLink>
          </li>
          <li role="none">
            <NuxtNavLink
              class="mainNavLink"
              to="/community"
              :tabindex="setTabIndex"
              role="menuitem"
              @keydown.native="trapMenuFocus($event)"
              @click.native="$emit('changemenu', 'close')"
            >
              Community
            </NuxtNavLink>
          </li>
          <li role="none">
            <NuxtNavLink
              class="mainNavLink"
              to="/questions"
              :tabindex="setTabIndex"
              role="menuitem"
              @keydown.native="trapMenuFocus($event)"
              @click.native="$emit('changemenu', 'close')"
            >
              Questions
            </NuxtNavLink>
          </li>
          <li role="none">
            <NuxtNavLink
              class="mainNavLink"
              to="/assets"
              :tabindex="setTabIndex"
              role="menuitem"
              @keydown.native="trapMenuFocus($event)"
              @click.native="$emit('changemenu', 'close')"
            >
              <abbr title="Digital Asset Manager">DAM</abbr>
            </NuxtNavLink>
          </li>
          <li role="none">
            <NuxtNavLink
              class="mainNavLink"
              to="/search"
              :tabindex="setTabIndex"
              role="menuitem"
              @keydown.native="trapMenuFocus($event)"
              @click.native="$emit('changemenu', 'close')"
            >
              <client-only>
                <BaseIcon
                  :class="$style.search"
                  icon="search"
                />
                <span class="sr-only">Search</span>
              </client-only>
            </NuxtNavLink>
          </li>
        </ul>
      </nav>
    </client-only>
  </div>
</template>

<script>
import { BaseIcon } from '@umarketing/osu-design-system'
import NuxtNavLink from './NuxtNavLink.vue'
export default {
  components: {
    BaseIcon,
    NuxtNavLink
  },
  props: {
    toggleNav: {
      type: String,
      default: 'closed'
    },
    toggleMobile: Boolean
  },
  computed: {
    isAuth () {
      return this.$store.state.isAuth || this.$initAuth
    },
    setTabIndex () {
      if (this.toggleMobile) {
        return -1
      } else {
        return 0
      }
    }
  },
  data () {
    const domainApi = process.env.DRUPAL_AUTH_URL || 'https://dev-decoupler.pantheonsite.io/saml/login'
    return {
      loginUrl: `${domainApi}?l=${window.location.href}`
    }
  },
  updated () {
    this.setNavAria()
  },
  watch: {
    toggleNav () {
      if (this.toggleNav === 'open') {
        this.$el.querySelector('#main-nav-links > li:first-child > a').setAttribute('tabindex', '0')
        this.$el.querySelector('#main-nav-links > li:first-child > a').focus()
      } else if (this.toggleNav === 'closed') {
        this.resetTabIndexMainNav()
      }
    },
    toggleMobile () {
      this.setNavAria()
    }
  },
  methods: {
    resetTabIndexMainNav () {
      this.$el.querySelectorAll('#main-nav-links > li > a').forEach((navLink) => {
        if (this.toggleMobile) {
          navLink.setAttribute('tabindex', '-1')
        }
      })
    },
    setNavAria () {
      const navUl = 'main-nav-links'
      const navTrigger = 'main-nav-trigger'
      const osuNav = this.$el.querySelector(`#${navUl}`)
      if (osuNav) {
        if (osuNav && !this.toggleMobile) {
          osuNav.removeAttribute('role')
          osuNav.removeAttribute('aria-labelledby')
          osuNav.querySelectorAll('li').forEach((navItem) => {
            navItem.removeAttribute('role')
          })
          osuNav.querySelectorAll('li a').forEach((navLink) => {
            navLink.removeAttribute('role')
          })
        } else {
          osuNav.setAttribute('role', 'menu')
          osuNav.setAttribute('aria-labelledby', navTrigger)
          osuNav.querySelectorAll('li').forEach((navItem) => {
            navItem.setAttribute('role', 'none')
          })
          osuNav.querySelectorAll('li a').forEach((navLink) => {
            navLink.setAttribute('role', 'menuitem')
          })
        }
      }
    },
    trapMenuFocus (e) {
      // for main nav menu links
      const osuNavLinks = document.querySelector('#main-nav-links')

      switch (e.keyCode) {
        case 9:
          // TAB KEY
          if (this.toggleMobile) {
            e.preventDefault()
          }
          break
        case 13:
        case 32:
          // ENTER OR SPACEBAR KEY
          if (this.toggleMobile) {
            e.preventDefault()
            this.resetTabIndexMainNav()
            e.currentTarget.click()
            this.$emit('changemenu', 'close')
          }
          break
        case 40:
          // DOWN ARROW
          if (this.toggleMobile) {
            e.preventDefault()
            this.resetTabIndexMainNav()
            if (e.currentTarget.parentElement.nextElementSibling !== null) {
              e.currentTarget.parentElement.nextElementSibling.querySelector('a').setAttribute('tabindex', '0')
              e.currentTarget.parentElement.nextElementSibling.querySelector('a').focus()
            } else {
              osuNavLinks.querySelector('li:first-child > a').setAttribute('tabindex', '0')
              osuNavLinks.querySelector('li:first-child > a').focus()
            }
          }
          break
        case 38:
          // UP ARROW
          if (this.toggleMobile) {
            e.preventDefault()
            this.resetTabIndexMainNav()
            if (e.currentTarget.parentElement.previousElementSibling !== null) {
              e.currentTarget.parentElement.previousElementSibling.querySelector('a').setAttribute('tabindex', '0')
              e.currentTarget.parentElement.previousElementSibling.querySelector('a').focus()
            } else {
              osuNavLinks.querySelector('li:last-child > a').setAttribute('tabindex', '0')
              osuNavLinks.querySelector('li:last-child > a').focus()
            }
          }
          break
        case 36:
          // HOME KEY
          if (this.toggleMobile) {
            e.preventDefault()
            this.resetTabIndexMainNav()
            osuNavLinks.querySelector('li:first-child > a').setAttribute('tabindex', '0')
            osuNavLinks.querySelector('li:first-child > a').focus()
          }
          break
        case 35:
          // END KEY
          if (this.toggleMobile) {
            e.preventDefault()
            this.resetTabIndexMainNav()
            osuNavLinks.querySelector('li:last-child > a').setAttribute('tabindex', '0')
            osuNavLinks.querySelector('li:last-child > a').focus()
          }
          break
      }
    }
  }
}
</script>
<style lang="scss">
  #main-nav-links {
    abbr {
      text-decoration-line: none;
    }
  }
  .sr-only {
    position:absolute;
    left:-10000px;
    top:auto;
    width:1px;
    height:1px;
    overflow:hidden;
  }
</style>
<style lang="scss" module>
  .search {
    font-size: 23px;
    padding: 0 0 0 8px;
    position: absolute;
    top: 12px;
    path {
      stroke-width: 1;
    }
  }

  .nav {
    @media (max-width:767px) {
      background: $color-gray-lightest;
      border-top: 1px solid $color-gray-lighter;
      padding: 0 16px;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 96;
    }

    nav {
      height: 100%;
    }

    ul {
      @media (max-width:767px) {
        display: block;
        padding: 0 16px 16px;
      }
      display: flex;
      flex-flow: row wrap;
      list-style-type: none;
      margin: 0;
      padding: 0;
    }

    li {
      display: block;
      margin: 0 32px 0 0;
      padding: 0;

      @media (min-width:768px) {
        &:last-child {
          margin: 0;
        }
      }
    }
  }
  .homeNav {
    ul {
      li {
        &:last-child {
          @media (min-width: 777px) {
            margin-left: auto;
          }
          a {
            color: $color-scarlet;
            height: 48px;
            width: 40px;
          }
        }
      }
    }
  }
</style>
