// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/@nuxt/webpack/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".errorPageWrapper_9qMIG{margin:48px 0}.errorPageWrapper_9qMIG ul{margin:0;padding:0 0 0 24px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"errorPageWrapper": "errorPageWrapper_9qMIG"
};
module.exports = ___CSS_LOADER_EXPORT___;
