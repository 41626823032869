/**
 * Client for JSON API server.
 * We use "jsonapi-parse" package to format responses :
 * It resolves relationships and included objects nicely in the final data object.
 */

import axios from 'axios'
import { Formatter } from 'sarala-json-api-data-formatter'
import * as rax from 'retry-axios'
import * as queryString from 'query-string'

const config = {
  baseURL: process.env.DRUPAL_API_URL ? process.env.DRUPAL_API_URL : 'https://live-decoupler.pantheonsite.io/api',
  pluralize: false,
  withCredentials: true
}

// Retry automatically 5 times to prevent random build errors
const interceptorId = rax.attach()
const axiosApi = axios.create(config)

export default {
  get: async (uri, params = null) => {
    const response = await getRequest(uri, params)
    const recursed = await recurseRequest(response, uri, params)
    const transformed = processJsonApi(recursed)
    transformed.data.forEach((node) => {
      const otherNode = recursed.data.find((r) => { return r.id === node.id })
      node.brand_content_children = otherNode.relationships.brand_content_children.data ?? []
    })
    return transformed
  }
}

async function getRequest (uri, params) {
  const response = null
  let axiosResponse = null
  // response = await api.get(uri, params)
  axiosResponse = await axiosApi.get(uri, { params })
    .catch((err) => {
      const url = config.baseURL + '/' + uri
      console.error('Error while getting data', url, err)
    })
  // const transformed = processJsonApi(axiosResponse.data)
  return axiosResponse.data
}

/**
 *  Recursivley builds a full list of the requested data by making additional
 *  requests as long as their is a "next" record from Drupal.
 * @param {Object} response JSONAPI response object
 * @param {Object} params The request params
 */
async function recurseRequest (response, uri, params) {
  // debugger
  if (!response.links.next) {
    return response
  }
  const query = `?${response.links.next.href.split('?')[1]}`
  const parsed = queryString.parse(query)
  const newResponse = await getRequest(uri, parsed)
  newResponse.data = [...response.data, ...newResponse.data]
  newResponse.included = [...response.included, ...newResponse.included]
  return recurseRequest(newResponse, uri, params)
}

function processJsonApi (data) {
  const formatter = new Formatter()
  const newData = formatter.includeOnly(['parent', 'resources', 'image', 'image.image', 'main_image', 'main_image.image']).deserialize({ ...data })

  // newData.data = transform(newData.data, transformMap)
  return newData
}
