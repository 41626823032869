<template>
  <div>
    <client-only>
      <SiteHeader
        :is-auth="$initHasAuth"
      />
    </client-only>
    <div class="container" :class="containerWidth">
      <VueAnnouncer />
      <main>
        <nuxt />
      </main>
    </div>
    <BaseFooter
      class="footer"
      site_name_prefix=""
      site_name="The Ohio State University"
      address_1="14 E. 15th Ave."
      address_2=""
      city="Columbus"
      state="OH"
      zip="43201"
      contact_email="brandcenter@osu.edu"
      contact_phone=""
      contact_tty=""
      :links="[ ]"
      :social_links="[
        {
          name: 'Facebook',
          url: 'http://www.facebook.com/osu',
          icon: 'icon-facebook'
        },
        {
          name: 'Instagram',
          url: 'https://instagram.com/theohiostateuniversity/',
          icon: 'icon-instagram'
        },
        {
          name: 'Twitter',
          url: 'http://twitter.com/OhioState',
          icon: 'icon-twitter'
        },
        {
          name: 'LinkedIn',
          url: 'https://www.linkedin.com/school/the-ohio-state-university/',
          icon: 'icon-linkedin'
        },
        {
          name: 'YouTube',
          url: 'http://www.youtube.com/user/OhioStateUniversity',
          icon: 'icon-youtube'
        },
        {
          name: 'TikTok',
          url: 'https://vm.tiktok.com/TTPdYEkbnP/',
          icon: 'icon-tiktok'
        }
      ]"
    >
      <template #links>
        <p class="bux_footer__link">
          <a
            href="http://www.osu.edu/webmaster.php"
            class="bux-link"
          >
            Request
          </a>
          an alternate format of this page
        </p>
        <p class="bux_footer__link">
          View
          <a
            href="https://go.osu.edu/privacy"
            class="bux-link"
          >
            privacy notice
          </a>
        </p>
        <p class="bux_footer__link">
          View
          <a
            href="https://go.osu.edu/nondiscrimination-notice"
            class="bux-link"
          >
            non-discrimination notice
          </a>
        </p>
        <p class="bux_footer__link">
          Update
          <button id="ot-sdk-btn" class="cookie-setting-link ot-sdk-show-settings" type="button">
            Cookie Settings
          </button>
        </p>
      </template>
      <template #default>
        <p>
          Page maintained by the <a class="bux-link" href="https://universitymarketing.osu.edu">Office of Marketing and Communications</a>
        </p>
      </template>
    </BaseFooter>
    <BackToTop />
  </div>
</template>

<script>
import { BaseFooter, BackToTop } from '@umarketing/osu-design-system'
import { debug } from '../store/index.js'
import SiteHeader from '~/components/SiteHeader.vue'
import scrollBehavior from '~/app/router.scrollBehavior.js'

export default {
  components: {
    BackToTop,
    BaseFooter,
    SiteHeader
  },
  created () {
    const allowedRoutes = this.$store.state.allowedRoutes
    const path = this.$store.getters.redirectPath
    const routeAllowed = allowedRoutes.includes(this.$route.fullPath)
    if (!this.$initAuth && path !== this.$route.fullPath && process.client && !routeAllowed) {
      // debug({ function: 'in default.vue', path, allowedRoutes, routeAllowed })
      this.$store.commit('setRequestedPath')
      this.$router.replace(path)
      return null
    }
    if (this.$initStudent) {
      const studentRoutes = this.$store.getters.linearNav('/brand-guidelines').map(item => item.navPath)
      const shouldRedirect = !studentRoutes.includes(this.$route.fullPath)
      // not redirect problem
      if (shouldRedirect) {
        // this.$router.replace('/brand-guidelines')
      }
    }
  },
  mounted () {
    if (this.$route.hash) {
      this.$nextTick(async () => {
        const scrollTo = await scrollBehavior(this.$route)
        window.scrollTo(0, scrollTo.y)
      })
    }
  },
  watch: {
    $route (val, oldVal) {
      if (process.client) {
        document.body.setAttribute('tabindex', '-1')
        document.body.focus()
        document.body.blur()
      }
    }
  },
  computed: {
    containerWidth () {
      if (this.$route.path === '/') {
        return 'wide'
      } else {
        return ''
      }
    }
  }
}
</script>

<style lang="scss">
.is-style-border img {
  border: solid 1px $color-gray;
}
p.has-background {
    padding: 20px 38px;
}
.is-style-callout-large {
  font-weight: 700;
  font-size:24px;
  padding-left: 48px;
  line-height:1.5em;
}
.is-style-callout-medium {
  font-size:20px;
  margin-left: $space-l;
  line-height:1.5em;
}
$colors-map: (
  'valid': (rgb(8, 158, 0)),
  'secondary-g': (rgb(144, 151, 56)),
  'warning': (rgb(255, 246, 100)),
  'secondary-h': (rgb(214, 88, 40)),
  'secondary-i': (rgb(255, 234, 174)),
  'black': (rgb(33, 35, 37)),
  'gray-darker': (rgb(63, 68, 67)),
  'secondary-j': (rgb(110, 187, 171)),
  'gray-lighter': (rgb(239, 241, 242)),
  'secondary-k': (rgb(221, 222, 198)),
  'scarlet-darker': (rgb(112, 7, 28)),
  'secondary-l': (rgb(133, 30, 94)),
  'dim': (rgba(0, 0, 0, 0.5)),
  'error': (rgb(255, 0, 0)),
  'white': (rgb(255, 255, 255)),
  'scarlet-darkest': (rgb(74, 5, 19)),
  'information': (rgb(0, 13, 181)),
  'gray-lightest': (rgb(246, 247, 248)),
  'gray-darkest': (rgb(33, 35, 37)),
  'gray-dark': (rgb(100, 106, 110)),
  'gray-light': (rgb(223, 227, 229)),
  'valid-light': (rgb(144, 151, 56)),
  'scarlet-dark': (rgb(150, 0, 0)),
  'secondary-a': (rgb(68, 35, 105)),
  'secondary-b': (rgb(146, 176, 215)),
  'gray-disabled': (rgb(204, 204, 204)),
  'secondary-c': (rgb(212, 223, 72)),
  'secondary-d': (rgb(38, 104, 109)),
  'secondary-e': (rgb(220, 170, 56)),
  'scarlet': (rgb(186, 12, 47)),
  'secondary-f': (rgb(69, 56, 49)),
  'gray': (rgb(148, 148, 148))
);

@each $name, $color in $colors-map {
  .has-#{$name}-color {
    color: $color;
  }
  .has-#{$name}-background-color {
    background-color: $color;
  }
}

</style>

<style lang="scss">

  button#ot-sdk-btn.cookie-setting-link {
    cursor: pointer;
    background: transparent;
    border: none;
    border-bottom: 1px solid $color-gray-darkest;
    background-repeat: no-repeat;
    padding: 0;
    position: relative;
    font-size: inherit;
    text-decoration: none;

    &:hover,
    &:focus {
      background: $color-white;
      border-bottom: 1px solid $color-scarlet;
      color: $color-scarlet;
    }
    &:focus {
      outline: 2px solid #41b6e6;
      outline-offset: 2px;
    }
  }
</style>
<style lang="scss">
  html {
    font-family: $font-text;
  }

  * {
    box-sizing: border-box;
  }

  @media (min-width: 768px) {
    .footer {
      padding-left: calc((100% - 750px) / 2 ) !important;
      padding-right: calc((100% - 750px) / 2 ) !important;
    }
  }
  @media (min-width: 992px) {
    .footer {
      padding-left: calc((100% - 970px) / 2 ) !important;
      padding-right: calc((100% - 970px) / 2 ) !important;
    }
  }
  @media (min-width: 1200px) {
    .footer {
      padding-left: calc((100% - 1170px) / 2 ) !important;
      padding-right: calc((100% - 1170px) / 2 ) !important;
    }
  }

  .footer {
    padding-bottom: 40px;
  }
  .bux-footer__logo a {
    max-width: 270px;
    width: 100% !important;
  }

  .container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    position: relative;

    @media (min-width: 768px) {
      width: 750px;
    }
    @media (min-width: 992px) {
      width: 970px;
    }
    @media (min-width: 1200px) {
      width: 100%;
      max-width: 1170px;
    }

    &.wide {
      margin: 0 auto;
      padding-right: 0;
      padding-left: 0;
      max-width: none;
      width: 100%;
    }
  }
  .bux-container  {
    @media (min-width: 768px) {
      width: 750px !important;
    }
    @media (min-width: 992px) {
      width: 970px !important;
    }
    @media (min-width: 1200px) {
      width: 100% !important;
      max-width: 1170px !important;
    }
  }
  .narrow {
    @media (min-width: 768px) {
      width: 75%;
    }
  }
  .bux-footer .icon::before {
    font-size: 1.25rem;
  }
  #ot-sdk-btn {
    color: #212325 !important;

    &:hover {
      color: $color-scarlet !important;
    }
  }

  // ========================================
  // KEYBOARD FOCUS for accessibility
  // see plugin: focus-visible.js
  .fc-button:focus,
  button:focus,
  a:focus {
    outline: none;
  }
  .js-focus-visible :focus:not(.focus-visible) {
    outline: none;
  }
  .js-focus-visible .focus-visible {
    outline: 2px solid #3492b8 !important;
    outline-offset: 2px !important;
  }
  // END KEYBOARD FOCUS
  // ========================================
</style>

<style lang="css">
  .page-enter-active, .page-leave-active {
    transition: opacity .2s ease;
  }

  .page-enter, .page-leave-active {
    opacity: 0;
  }
</style>
