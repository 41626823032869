export default async function auth ({ app, env, route, params, store, redirect }) {
  const isAuth = await store.dispatch('checkAuth')
  const allowedRoutes = store.state.allowedRoutes
  const path = store.getters.redirectPath
  params.auth = isAuth
  params.env = env
  const shouldRedirect = !isAuth && route.fullPath !== path && process.client
  if (shouldRedirect) {
    store.commit('setRequestedPath')
    return path
  }
}
